export const IMAGE_PREFIX = '/_ipx/f_webp';
export const IMAGE_PATH = `${IMAGE_PREFIX}/images`;

export const LOCALE_CODES = {
  ru: 'ru',
  en: 'en',
};

export const LOCALES = {
  [LOCALE_CODES.ru]: {
    code: LOCALE_CODES.ru,
    file: 'ru.json',
    name: 'Rus',
  },
  [LOCALE_CODES.en]: {
    code: LOCALE_CODES.en,
    file: 'en.json',
    name: 'Eng',
  },
};

export const LOCALES_ARRAY = Object.values(LOCALES);
